import React from 'react';
import bemCn from 'bem-cn';
import moment from 'moment-timezone';
import Calendar from 'react-calendar';
import CookieService from '../../services/cookie.service';
import { EVENTS_NAME } from '../../consts/event.consts';
import { RouteFormatter } from '../../routes';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { getRandomCookieTestSSR } from '../../helpers/ssr';
import { CALENDAR_TYPE, DAY_TYPE, LENGTHS_SPOT, QTYS_SPOT, SIGN_UP_SRC, SPOT_SRC, SPOT_SRC_MEMBERSHIP } from '../../consts/spot.consts';
import ModalMobile from '../modal-mobile/modal-mobile-ssr';
import SniffSelect from '../sniff-select/select-ssr';
import SniffButton from '../sniff-button';
import Preloader from '../preloader';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import SvgMembership from 'src/assets/svg/SvgMembership.svg';
import SvgAfternoon from 'src/assets/svg/SvgAfternoon.svg';
import SvgEvening from 'src/assets/svg/SvgEvening.svg';
import SvgMorning from 'src/assets/svg/SvgMorning.svg';
import SvgTips from 'src/assets/svg/SvgTips.svg';
import SvgEdit from 'src/assets/svg/SvgEdit.svg';
import './guest-calendar.scss';

const b = bemCn('spot-calendar');

const TodayDot = ({ color = 'green' }) => <div className={b('today-dot').mix(color)} />;
const timeListFormatter = (time) => {
    let newTime = time;
    if (time < 1) {
        newTime = time + 12;
    } else if (time > 12.5) {
        newTime = time % 12;
    }
    if (time >= 24) {
        newTime = (time % 12) + 12;
    }
    if (time % 1 > 0) {
        newTime = `${Math.floor(newTime)}.30`;
    }
    if (time > 11.5 && time < 24) {
        newTime += 'pm';
    } else {
        newTime += 'am';
    }

    return newTime;
};

const RenderTimePicker = ({ modalToggle, availableTimesData, qtyPick, lengthPick }) => (
    <div className="settings">
        Available times for{' '}
        <span className="settings-toggle" onClick={modalToggle}>
            {`${availableTimesData?.availableTimes.quantity || qtyPick.value} ${
                (availableTimesData?.availableTimes.quantity || qtyPick.value) === 1 ? 'dog' : 'dogs'
            }, ${availableTimesData?.availableTimes.length || lengthPick.value} ${
                (availableTimesData?.availableTimes.length || lengthPick.value) <= 1 ? 'hour' : 'hours'
            }`}
            <SvgEdit />
        </span>
    </div>
);

const RenderTimeList = ({ startTime, availableTimes, handleChooseTime, lengthPick }) => {
    const isMemberTime = availableTimes.timesForMembers.includes(startTime);
    return (
        <SniffButton color="secondary" size="lg" block className="time-book" onClick={() => handleChooseTime(startTime, isMemberTime)}>
            {timeListFormatter(startTime)}
            {' - '}
            {timeListFormatter(startTime + lengthPick.value)}
            {isMemberTime && (
                <div className="member-icon">
                    <SvgMembership />
                </div>
            )}
        </SniffButton>
    );
};

const SpotCalendar = ({
    userDetails,
    spotId,
    showCalendar,
    handlePick = null,
    toggleCalendar,
    data,
    setData,
    useEffect,
    useState,
    usePrevious,
    isMobile,
    spotDetails,
    useGetAvailableTimes,
    useLazyGetIntentCal,
    useUpdateIntent,
    Select,
    trackEvent,
}) => {
    if (!spotId) {
        return <Preloader fullWindow />;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const onCompletedFunc = (data) => {
        if (data.intent) {
            const sLength = data.intent.length || lengthOpt[1].value;
            const sQty = data.intent.quantity || qtyOpt[0].value;
            getAvailableTimes({
                variables: {
                    spotId: spotId,
                    date: moment(datePick).format('YYYY-MM-DD'),
                    length: sLength,
                    quantity: sQty,
                },
            });
            if (handlePick) {
                const savedDate = new Date(data.intent.date);
                if (today - savedDate > 0) {
                    setDatePick(new Date(today));
                } else {
                    setDatePick(new Date(savedDate));
                }
                setLengthPick({
                    label: `${sLength} ${sLength <= 1 ? 'hour' : 'hours'}`,
                    value: sLength,
                });
                setQtyPick({
                    label: `${sQty} ${sQty <= 1 ? 'dog' : 'dogs'}`,
                    value: sQty,
                });
            }
        } else {
            getAvailableTimes({
                variables: {
                    spotId: spotId,
                    date: moment(datePick).format('YYYY-MM-DD'),
                },
            });
        }
    };

    const { getIntent, loading: savedIntentLoading } = useLazyGetIntentCal(onCompletedFunc);
    const { updateIntent } = useUpdateIntent();
    const { getAvailableTimes, data: availableTimesData, loading: availableTimesLoading } = useGetAvailableTimes();
    const [calendarType, setCalType] = useState(isMobile ? CALENDAR_TYPE.WEEK : CALENDAR_TYPE.MONTH);
    const [datePick, setDatePick] = useState(today);
    const [dayPick, setDayPick] = useState(null);
    const [lengthPick, setLengthPick] = useState({});
    const [qtyPick, setQtyPick] = useState({});
    const [ableBack, setAbleBack] = useState(false);
    const [settingModal, setSettingModal] = useState(false);
    const [reqMemberModal, setReqMemberModal] = useState(false);
    const [availableTimes, setAvailableTimes] = useState({});
    const [disabledDates, setDisabledDates] = useState([]);
    const isRightDate = moment(datePick).format('YYYY-MM-DD') === availableTimesData?.availableTimes.startDate;
    const prevLengthPick = usePrevious(lengthPick.value);
    const [timesMorning, setTimesMorning] = useState([]);
    const [timesAfternoon, setTimesAfternoon] = useState([]);
    const [timesEvening, setTimesEvening] = useState([]);

    const emptyMorning =
        availableTimesData && (!isRightDate || (isRightDate && availableTimesData.availableTimes.timesAll.filter((t) => t < 12) == 0));
    const emptyAfternoon =
        availableTimesData && (!isRightDate || (isRightDate && availableTimesData.availableTimes.timesAll.filter((t) => t >= 12 && t < 17) == 0));
    const emptyEvening =
        availableTimesData && (!isRightDate || (isRightDate && availableTimesData.availableTimes.timesAll.filter((t) => t >= 17) == 0));
    const [extMorning, setExtMorning] = useState(false);
    const [extEvening, setExtEvening] = useState(false);

    const renderEmptyList = () => {
        if (!isRightDate) {
            return true;
        }

        let count = 0;
        switch (dayPick) {
            case DAY_TYPE.MORNING:
                count += availableTimesData?.availableTimes.timesAll?.filter((t) => t < 12).length;
                break;
            case DAY_TYPE.AFTERNOON:
                count += availableTimesData?.availableTimes.timesAll?.filter((t) => t >= 12 && t < 17).length;
                break;
            case DAY_TYPE.EVENING:
                count += availableTimesData?.availableTimes.timesAll?.filter((t) => t >= 17).length;
                break;
            default:
                break;
        }
        return !count;
    };

    const minLength = spotDetails?.minimumLength || 0.5;
    const maxLength = spotDetails?.maximumLength || 18;
    const lengthOpt = LENGTHS_SPOT.filter((v) => v >= minLength && v <= maxLength).map((v) => ({
        label: `${v} ${v <= 1 ? 'hour' : 'hours'}`,
        value: v,
    }));

    const maxDogs = spotDetails?.maximumDogsAllowed || 20;
    const qtyOpt = QTYS_SPOT.filter((v) => v <= maxDogs).map((v) => ({
        label: `${v} ${v == 1 ? 'dog' : 'dogs'}`,
        value: v,
    }));

    const thisWeek = [];
    const isoWeek = moment(datePick).day();
    for (let i = 0; i <= 6; i++) {
        const diff = i - isoWeek;
        thisWeek.push(new Date(moment(datePick).add(diff, 'days')));
    }

    const updateAvailTimes = () => {
        getAvailableTimes({
            variables: {
                spotId: spotId,
                date: moment(datePick).format('YYYY-MM-DD'),
                length: lengthPick.value || undefined,
                quantity: qtyPick.value || undefined,
            },
        });
    };

    useEffect(() => {
        if (datePick !== today) {
            updateAvailTimes();
        }
    }, [datePick]);

    useEffect(() => {
        if (calendarType === CALENDAR_TYPE.WEEK) {
            if (thisWeek.includes(thisWeek.find((d) => moment(today).diff(d, 'days') === 0))) {
                setAbleBack(false);
            } else {
                setAbleBack(true);
            }
        } else {
            if (moment(today).format('MM') === moment(datePick).format('MM')) {
                setAbleBack(false);
            } else {
                setAbleBack(true);
            }
        }
    }, [datePick, calendarType]);

    useEffect(() => {
        if (lengthPick.value && prevLengthPick) {
            setDisabledDates([]);
        }
    }, [lengthPick.value]);

    useEffect(() => {
        if (availableTimesData) {
            const unDates = [];
            if (availableTimesData.availableTimes.unavailableDates.length > 0) {
                availableTimesData.availableTimes.unavailableDates.map((d) => {
                    if (!disabledDates.find((v) => v === d)) {
                        unDates.push(d);
                    }
                });
            }
            setLengthPick({
                label: `${availableTimesData.availableTimes.length || 1} ${availableTimesData.availableTimes.length <= 1 ? 'hour' : 'hours'}`,
                value: availableTimesData.availableTimes.length || 1,
            });
            setQtyPick({
                label: `${availableTimesData.availableTimes.quantity || 1} ${availableTimesData.availableTimes.quantity <= 1 ? 'dog' : 'dogs'}`,
                value: availableTimesData.availableTimes.quantity || 1,
            });

            if (availableTimesData.availableTimes.startDate !== moment(datePick).format('YYYY-MM-DD')) {
                if (!spotDetails) {
                    return;
                }
                unDates.push(moment(datePick).format('YYYY-MM-DD'));
                setDisabledDates([...disabledDates, ...unDates]);
                setTimesMorning([]);
                setTimesAfternoon([]);
                setTimesEvening([]);
                setAvailableTimes({ times: [] });
            } else {
                setDisabledDates([...disabledDates, ...unDates]);
            }

            setAvailableTimes(availableTimesData.availableTimes);

            if (availableTimesData.availableTimes.timesAll && moment(datePick).format('YYYY-MM-DD') === availableTimesData.availableTimes.startDate) {
                if (extMorning) {
                    setTimesMorning(availableTimesData.availableTimes.timesAll.filter((t) => t < 12));
                } else {
                    setTimesMorning(availableTimesData.availableTimes.timesAll.filter((t) => t >= 7 && t < 12));
                }

                setTimesAfternoon(availableTimesData.availableTimes.timesAll.filter((t) => t >= 12 && t < 17));

                if (extEvening) {
                    setTimesEvening(availableTimesData.availableTimes.timesAll.filter((t) => t >= 17));
                } else {
                    setTimesEvening(availableTimesData.availableTimes.timesAll.filter((t) => t >= 17 && t <= 20));
                }

                // SET DEFAULT DAY TYPE THE FIRST TIME
                const earliestTime = availableTimesData.availableTimes.timesAll[0];
                if (!dayPick) {
                    if (earliestTime < 12) {
                        setDayPick(DAY_TYPE.MORNING);
                    } else if (earliestTime >= 17) {
                        setDayPick(DAY_TYPE.EVENING);
                    } else {
                        setDayPick(DAY_TYPE.AFTERNOON);
                    }
                }
            } else {
                setTimesMorning([]);
                setTimesAfternoon([]);
                setTimesEvening([]);
                setAvailableTimes({ ...availableTimes, times: [] });
            }
        }
    }, [availableTimesData]);

    useEffect(() => {
        if (!isRightDate) {
            return;
        }
        if (extMorning) {
            setTimesMorning(availableTimesData.availableTimes.timesAll.filter((t) => t < 12));
        }
        if (extEvening) {
            setTimesEvening(availableTimesData.availableTimes.timesAll.filter((t) => t >= 17));
        }
    }, [extMorning, extEvening]);

    useEffect(() => {
        getIntent({ variables: { spotId } });

        if (trackEvent) {
            trackEvent({ name: EVENTS_NAME.guest_calendar_view });
        }
    }, []);

    const handleNext = () => {
        if (calendarType === CALENDAR_TYPE.WEEK) {
            setDatePick(new Date(moment(datePick).add(7, 'days')));
        } else {
            setDatePick(new Date(moment(datePick).add(1, 'months')));
        }
    };

    const handlePrev = () => {
        if (calendarType === CALENDAR_TYPE.WEEK) {
            let newDate = moment(datePick).subtract(7, 'days');
            if (moment(today).diff(newDate, 'days') > 0) {
                newDate = today;
            }
            setDatePick(new Date(newDate));
        } else {
            let newDate = moment(datePick).subtract(1, 'months');
            if (moment(today).diff(newDate, 'days') > 0) {
                newDate = today;
            }
            setDatePick(new Date(newDate));
        }
    };

    const modalToggle = () => setSettingModal(!settingModal);
    const reqMemberToggle = () => setReqMemberModal(!reqMemberModal);

    const handleDayPick = (dayType) => setDayPick(dayType);

    const changeQty = async (qty) => {
        setQtyPick(qty);
        if (handlePick) {
            await updateIntent({
                variables: {
                    spotId: spotId,
                    quantity: qty.value,
                },
            });
        }
    };

    const changeLength = async (length) => {
        setLengthPick(length);
        if (handlePick) {
            await updateIntent({
                variables: {
                    spotId: spotId,
                    length: length.value,
                },
            });
        }
    };

    const handleApplySetting = () => {
        if (!lengthPick || !qtyPick) {
            return;
        }
        if (handlePick) {
            setData({
                ...data,
                length: lengthPick.value || undefined,
                quantity: qtyPick.value || undefined,
            });
        }
        updateAvailTimes();
        modalToggle();
    };

    const handleChooseTime = async (startTime, isMemberTime) => {
        await updateIntent({
            variables: {
                spotId: spotId,
                date: moment(datePick).format('YYYY-MM-DD'),
                length: lengthPick.value,
                quantity: qtyPick.value,
                time: startTime,
                sessionUuid: userDetails.id ? undefined : CookieService.get('sniff_session_uuid'),
            },
        });

        getRandomCookieTestSSR(COOKIE_PARAM_NAME.ONE_PAGE_CHECKOUT_TEST);

        if (!userDetails.id) {
            if (showCalendar === SPOT_SRC.WEB_SPOT) {
                CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.CHECKOUT_WEB, { expires: 1 });
            } else {
                CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.CALENDAR_WEB, { expires: 1 });
            }
        }

        if (handlePick) {
            if (isMemberTime && !spotDetails.isMember) {
                setReqMemberModal(true);
                return;
            }
            handlePick();
            toggleCalendar();
            return;
        }

        if (isMemberTime) {
            setReqMemberModal(true);
            return;
        }

        // const redirectUrl = `${RouteFormatter.subscriptionsNew()}?src=${SPOT_SRC_MEMBERSHIP.WEB_CHECKOUT}&id=${spotId}`;
        // CookieService.set(COOKIE_PARAM_NAME.REDIRECT, redirectUrl, { expires: 1 });
        // window.location = RouteFormatter.signIn();
        window.location = RouteFormatter.subscriptionsNew(null, {
            query: { src: SPOT_SRC_MEMBERSHIP.WEB_CHECKOUT, id: spotId },
        });

        // if (spotDetails.isMember) {
        //     window.location = RouteFormatter.reserve({ id: spotId }, { query: { src: showCalendar } });
        // } else if (isMemberTime && !spotDetails.isMember) {
        //     setReqMemberModal(true);
        // } else if (!isMemberTime) {
        //     window.location = RouteFormatter.reserve({ id: spotId }, { query: { src: showCalendar } });
        // }
    };

    const handleReqMember = async () => {
        reqMemberToggle();
        window.location = RouteFormatter.membershipsDetail({ id: spotId }, { query: { src: SPOT_SRC_MEMBERSHIP.WEB_CALENDAR, force: true } });
    };

    if ((userDetails.id !== null && savedIntentLoading) || !availableTimes) {
        return <Preloader full />;
    }

    return (
        <div className={b.mix(!isMobile ? 'desktop-screen' : '')}>
            <div className="desktop-header-block">
                <div className={b('header')}>
                    <div className="left">
                        <span
                            className={`calendar-type d-block d-md-none ${calendarType === CALENDAR_TYPE.WEEK ? 'active' : ''} `}
                            onClick={() => setCalType(CALENDAR_TYPE.WEEK)}
                        >
                            Week
                        </span>
                        <span
                            className={`calendar-type d-block d-md-none ${calendarType === CALENDAR_TYPE.MONTH ? 'active' : ''} `}
                            onClick={() => setCalType(CALENDAR_TYPE.MONTH)}
                        >
                            Month
                        </span>
                        <p className="monthly-text snif-p snif-medium mb-0 d-none d-md-block">{moment(datePick).format('MMMM YYYY')}</p>
                    </div>
                    <div className="right">
                        <span className={ableBack ? 'active' : ''} onClick={() => ableBack && handlePrev()}>
                            <SvgChevronLeft />
                        </span>
                        <span className="active" onClick={handleNext}>
                            <SvgChevronRight />
                        </span>
                    </div>
                </div>
                <div className={b('date_picker')}>
                    {calendarType === CALENDAR_TYPE.WEEK ? (
                        <div className="weekly">
                            {thisWeek.map((d, i) => {
                                const isActive = moment(d).diff(datePick, 'days') === 0;
                                const isToday = moment(d).isSame(moment(), 'days');
                                const isDisabled = moment(d).diff(moment(), 'days') < 0;
                                const isSoldOut = disabledDates.includes(moment(d).format('YYYY-MM-DD'));

                                return (
                                    <div
                                        key={i}
                                        className={`w_item ${isActive ? 'active' : ''} ${isDisabled ? 'disabled' : ''} ${
                                            isSoldOut ? 'sold-out' : ''
                                        }`}
                                        onClick={() => !isDisabled && !isSoldOut && setDatePick(d)}
                                    >
                                        <span className="i_text snif-s2">{moment(d).format('dd')}</span>
                                        <span className="i_num snif-m2">{moment(d).format('D')}</span>
                                        {isToday && <TodayDot color={isActive ? 'white' : 'green'} />}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="monthly">
                            {isMobile && <p className="monthly-text snif-p snif-medium">{moment(datePick).format('MMMM YYYY')}</p>}
                            <Calendar
                                onChange={setDatePick}
                                activeStartDate={datePick}
                                value={datePick}
                                minDate={today}
                                showNavigation={false}
                                calendarType="US"
                                formatShortWeekday={(loc, date) => moment(date).format('dd')}
                                minDetail="month"
                                view="month"
                                tileDisabled={({ date }) => disabledDates.includes(moment(date).format('YYYY-MM-DD'))}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={b('separator')} />
            <div className={b('availability')}>
                <div className="date-picked snif-m2 snif-semibold">
                    {`${moment(today).diff(datePick, 'days') === 0 ? 'Today' : moment(datePick).format('dddd')}, ${moment(datePick).format(
                        'MMMM D'
                    )}`}
                </div>
                {availableTimesLoading ? (
                    <Preloader full />
                ) : (isoWeek === 0 || isoWeek === 6) && (!isRightDate || disabledDates.find((d) => d === moment(datePick).format('YYYY-MM-DD'))) ? (
                    <>
                        <RenderTimePicker {...{ modalToggle, availableTimesData, qtyPick, lengthPick }} />
                        <div className="settings snif-p mb-3">Bummer! There are no available times for the selected date.</div>
                        <div className="unaval-tips">
                            <span className="tips-ico">
                                <SvgTips />
                            </span>
                            <p className="mb-0">
                                <span className="snif-semibold">Tip</span>: weekends are very popular! Try booking more in advance to find available
                                times.
                            </p>
                        </div>
                        <p
                            className={`my-fav-spots snif-p snif-medium c-pointer ${calendarType === CALENDAR_TYPE.MONTH ? 'monthly' : ''}`}
                            onClick={() => (window.location.href = RouteFormatter.favorites())}
                        >
                            View my favorite spots
                        </p>
                    </>
                ) : (
                    <>
                        <div className="date-sticky">
                            <RenderTimePicker {...{ modalToggle, availableTimesData, qtyPick, lengthPick }} />
                            <div className="time-picker">
                                <div
                                    className={`day-button ${dayPick === DAY_TYPE.MORNING ? 'day-active' : ''} ${emptyMorning ? 'day-empty' : ''}`}
                                    onClick={() => handleDayPick(DAY_TYPE.MORNING)}
                                >
                                    <SvgMorning className="morning" />
                                    <span className="snif-s1 snif-medium">Morning</span>
                                </div>
                                <div
                                    className={`day-button ${dayPick === DAY_TYPE.AFTERNOON ? 'day-active' : ''} ${
                                        emptyAfternoon ? 'day-empty' : ''
                                    }`}
                                    onClick={() => handleDayPick(DAY_TYPE.AFTERNOON)}
                                >
                                    <SvgAfternoon className="afternoon" />
                                    <span className="snif-s1 snif-medium">Afternoon</span>
                                </div>
                                <div
                                    className={`day-button ${dayPick === DAY_TYPE.EVENING ? 'day-active' : ''} ${emptyEvening ? 'day-empty' : ''}`}
                                    onClick={() => handleDayPick(DAY_TYPE.EVENING)}
                                >
                                    <SvgEvening className="evening" />
                                    <span className="snif-s1 snif-medium">Evening</span>
                                </div>
                            </div>
                        </div>
                        <div className="time-list">
                            {dayPick === DAY_TYPE.MORNING && (
                                <>
                                    {isRightDate && availableTimesData?.availableTimes.timesAll?.find((t) => t < 7) !== undefined && !extMorning && (
                                        <SniffButton onClick={() => setExtMorning(true)} color="secondary" size="lg" block className="time-book">
                                            Show times before 7am
                                        </SniffButton>
                                    )}
                                    {timesMorning.map((startTime, idx) => (
                                        <RenderTimeList key={idx} {...{ startTime, availableTimes, handleChooseTime, lengthPick }} />
                                    ))}
                                </>
                            )}
                            {dayPick === DAY_TYPE.AFTERNOON &&
                                timesAfternoon.map((startTime, idx) => (
                                    <RenderTimeList key={idx} {...{ startTime, availableTimes, handleChooseTime, lengthPick }} />
                                ))}
                            {dayPick === DAY_TYPE.EVENING && (
                                <>
                                    {timesEvening.map((startTime, idx) => (
                                        <RenderTimeList key={idx} {...{ startTime, availableTimes, handleChooseTime, lengthPick }} />
                                    ))}
                                    {isRightDate && availableTimesData?.availableTimes.timesAll?.find((t) => t > 20) !== undefined && !extEvening && (
                                        <SniffButton onClick={() => setExtEvening(true)} color="secondary" size="lg" block className="time-book">
                                            Show times after 8pm
                                        </SniffButton>
                                    )}
                                </>
                            )}
                            {renderEmptyList() && <p className="empty-list snif-p">There are no available times with the selected preferences.</p>}
                        </div>
                    </>
                )}
            </div>
            <ModalMobile isOpen={settingModal} toggle={modalToggle} title="Change preferences" className="calendar-setting-modal">
                <p className="pref-label snif-p snif-medium mb-1">How many dogs are you bringing?</p>
                <SniffSelect Select={Select} name="qty" options={qtyOpt} value={qtyPick} onChange={changeQty} maxMenuHeight={200} className="mb-3" />
                <p className="pref-label snif-p snif-medium mb-1">How long will you stay?</p>
                <SniffSelect
                    Select={Select}
                    name="length"
                    options={lengthOpt}
                    value={lengthPick}
                    onChange={changeLength}
                    maxMenuHeight={150}
                    className="mb-4"
                />
                <SniffButton onClick={handleApplySetting} className="apply-button mb-1" size="lg" block>
                    Apply settings
                </SniffButton>
                <SniffButton onClick={modalToggle} className="cancel-button" color="secondary" size="lg" block>
                    Cancel
                </SniffButton>
            </ModalMobile>
            <ModalMobile isOpen={reqMemberModal} toggle={reqMemberToggle} className="req-member-modal">
                <div className="req-member-title">
                    <div className="member-title-icon">
                        <SvgMembership />
                    </div>
                    <div className="member-title-text snif-p snif-medium">Only members can book this time</div>
                </div>
                <div className="snif-p text-deep mb-3">
                    This host is rewarding loyal guests by reserving them specific times, as well as other additional benefits and discounts.
                </div>
                <div className="snif-p snif-medium mb-2">Become a member now to book this time</div>
                <SniffButton onClick={handleReqMember} className="req-button" size="lg" block>
                    Learn more
                </SniffButton>
                <SniffButton onClick={reqMemberToggle} className="no-button" color="secondary" block>
                    Not right now
                </SniffButton>
            </ModalMobile>
        </div>
    );
};

export default SpotCalendar;
