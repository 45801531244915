import React from 'react';
import arcRules1 from '../assets/img/arc-rules-1.png';
import arcRules2 from '../assets/img/arc-rules-2.png';
import arcRules3 from '../assets/img/arc-rules-3.png';
import arcRules4 from '../assets/img/arc-rules-4.png';
import SvgGuestRulesGRDogLeash from 'src/assets/svg/SvgGuestRulesGRDogLeash.svg';
import SvgGuestRulesGRDogPoopClean from 'src/assets/svg/SvgGuestRulesGRDogPoopClean.svg';
import SvgGuestRulesGRShieldCheck from 'src/assets/svg/SvgGuestRulesGRShieldCheck.svg';
import SvgGuestRulesGRTaskListPin from 'src/assets/svg/SvgGuestRulesGRTaskListPin.svg';
import SvgGuestRulesGRTimeClock from 'src/assets/svg/SvgGuestRulesGRTimeClock.svg';
import SvgGuestRulesGRToiletSign from 'src/assets/svg/SvgGuestRulesGRToiletSign.svg';

export const HAS_REVIEWS = {
    NO: 'NO',
    HOST: 'HOST',
    GUEST: 'GUEST',
    BOTH: 'BOTH',
};

export const USER_TEST_GROUP = {
    SEARCH_ALGO_2024: 'backSearchAlgo2024',
    BOOK_CAL: 'frontBookingCalendar',
    GODD_SPOT: 'frontGoodSpot',
    SHORT_ADD_SPOT: 'frontShortSpotFlow',
    MOBILE_ADOPTION: 'frontMobileAdoption',
    ONLY_BIG_SPOTS: 'frontOnlyBigSpots',
    SEARCH_FILTERS: 'frontSearchFilters',
    SEO_CITY: 'frontSeoCityPage',
    SUPER_SHORT_ADD_SPOT: 'frontSuperShortAddSpot',
    HOME_PAGE: 'frontHomePhoto',
    JUNE_SHORT_ADD_SPOT: 'frontShortSpot0622',
    HOST_POST_PUBLISH: 'frontHostPostPublish',
    HOST_ADD_SPOT_PHOTOS: 'frontAddSpotReqPhotos',
    HOME_SEARCH: 'frontHomeSearchNew',
    HOST_EARNINGS: 'frontHostEarnings',
    HOST_TEN_PHOTOS_SPOT: 'frontSpotTenPhotos',
    QUICK_BOOK_BTN_SEARCH: 'frontSearchPage',
    HOME_PAGE_V2: 'frontHomePageV2',
    LISTING_AA_TEST: 'frontTest',
    HOST_LAND_ADD_SPOT: 'frontHostLandingAddSpot',
    POST_PUB_ADD_SPOT: 'frontPostPublish',
    HOME_SEARCH_V3: 'homeSearchV3',
    PHOTO_WARNING_ALERT: 'frontPhotoWarningAlert',
    HOST_SPOT_FLOW_PHOTO_COPY: 'hostSpotFlowPhotoCopy',
    HOST_SPOT_FLOW_PHOTO_COPY2: 'hostSpotFlowPhotoCopy2',
    HOST_ADD_SPOT_FLOW: 'frontHostSignupFlow',
    ONE_PAGE_CHECKOUT: 'frontOnePageCheckout',
    SPOT_DETAIL_TOP_BAR: 'frontSpotDetailTopBar',
    GUEST_AUTH_TEST: 'frontAuthForSpotContent',
};

export const GUEST_RULES_LIST = [
    {
        icon: <SvgGuestRulesGRDogLeash />,
        text: 'Dogs must be leashed when entering and exiting the spot.',
    },
    {
        icon: <SvgGuestRulesGRShieldCheck />,
        text: 'Make sure the area is safe before bringing your dog in.',
    },
    {
        icon: <SvgGuestRulesGRTimeClock />,
        text: 'Never enter a spot before your booking start time and never leave late.',
    },
    {
        icon: <SvgGuestRulesGRDogPoopClean />,
        text: 'Pick up after your dogs. Leave the spot as it was when you arrived.',
    },
    {
        icon: <SvgGuestRulesGRTaskListPin />,
        text: 'Always review and abide by spot specific rules.',
    },
    {
        icon: <SvgGuestRulesGRToiletSign />,
        text: 'Unless otherwise stated, restrooms are not available on site.',
    },
];

export const HOST_RULES_LIST = [
    {
        icon: arcRules1,
        text: 'Never meet guest dogs unless you confirm with the guest in advance.',
    },
    {
        icon: arcRules2,
        text: 'If you have dogs, make sure they are kept securely away from the spot.',
    },
    {
        icon: arcRules3,
        text: 'Try to keep your spot reasonably clean and free of hazards.',
    },
    {
        icon: arcRules4,
        text: 'Make sure any gates are unlocked and guests can easily access your spot.',
    },
];
